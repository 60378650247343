<template>
  <v-app id="vendors-view">
    <v-main>
      <MetadataLoadingGuard>
        <ProvideLayoutManager :layout-manager="manager">
          <BoundingBox v-slot="{ height: containerHeight, top: topMargin }">
            <VendorsTabs
              v-if="showTabs"
              :height="containerHeight - toolbarHeight"
            >
              <template #assign-vendors>
                <AssignVendors
                  :client-id="clientId"
                  page-title="vendors.tabs.assign-vendors.page-title"
                />
              </template>
              <template #assign-job-types>
                <AssignJobTypes
                  :client-id="clientId"
                  page-title="vendors.tabs.assign-job-types.page-title"
                />
              </template>
            </VendorsTabs>
            <div v-else-if="showVendorsTabOnly || showJobTypeTabOnly">
              <AssignVendors
                v-if="showVendorsTabOnly"
                :client-id="clientId"
                page-title="vendors.tabs.assign-vendors.page-title"
              />
              <AssignJobTypes
                v-if="showJobTypeTabOnly"
                :client-id="clientId"
                page-title="vendors.tabs.assign-job-types.page-title"
              />
            </div>
            <div v-else class="h-100 d-flex justify-center align-center">
              <span class="text-h3">
                {{ $t('vendors.tabs.no-tabs') }}
              </span>
            </div>
            <AppLayoutAlertLauncher />
            <AppLayoutDialogForm />
            <AppLayoutSnackBarLauncher />
            <AppLayoutSideSheet :top="topMargin" />
          </BoundingBox>
        </ProvideLayoutManager>
      </MetadataLoadingGuard>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue'

import AppLayoutAlertLauncher from '@/tt-app-layout/components/AppLayoutAlertLauncher.vue'
import AppLayoutDialogForm from '@/tt-app-layout/components/AppLayoutDialogForm.vue'
import AppLayoutSnackBarLauncher from '@/tt-app-layout/components/AppLayoutSnackBarLauncher.vue'
import AppLayoutSideSheet from '@/tt-app-layout/components/AppLayoutSideSheet.vue'
import LayoutManager from '@/tt-app-layout/LayoutManager'
import ProvideLayoutManager from '@/tt-app-layout/components/ProvideLayoutManager.vue'

import BoundingBox from '../../components/BoundingBox.vue'
import VendorsTabs from '../components/VendorsTabs.vue'

import MetadataLoadingGuard from '../../components/MetadataLoadingGuard.vue'
import AssignVendors from '@/tt-widget-views/vendors/components/AssignVendors.vue'
import AssignJobTypes from '@/tt-widget-views/vendors/components/AssignJobTypes.vue'

import { TOOLBAR_HEIGHT } from '../constants'
import { AllowEntityOperationsProvider } from '@/types'
import { Resources } from '@/tt-entity-design/src/types'

export default Vue.extend({
  name: 'ClientVendorsView',
  components: {
    AssignVendors,
    AppLayoutDialogForm,
    AppLayoutSideSheet,
    AppLayoutSnackBarLauncher,
    BoundingBox,
    MetadataLoadingGuard,
    ProvideLayoutManager,
    VendorsTabs,
    AppLayoutAlertLauncher,
    AssignJobTypes,
  },
  provide(): AllowEntityOperationsProvider {
    return {
      allowEntityCreation: null,
      allowEntityDelete: null,
      allowEntityEdit: [Resources.TASK_SITE_INSTRUCTIONS],
      allowEntityActions: [Resources.CLIENTS, Resources.TASK_SITE_INSTRUCTIONS],
    }
  },
  props: {
    clientId: { type: Number, required: true },
    enableAssignVendorToClient: {
      type: Boolean,
      default: true,
    },
    enableAssignVendorToTaskType: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      manager: new LayoutManager(this.$eventManager),
    }
  },
  computed: {
    toolbarHeight(): number {
      return TOOLBAR_HEIGHT
    },
    showTabs() {
      return (
        this.enableAssignVendorToClient && this.enableAssignVendorToTaskType
      )
    },
    showVendorsTabOnly() {
      return (
        this.enableAssignVendorToClient && !this.enableAssignVendorToTaskType
      )
    },
    showJobTypeTabOnly() {
      return (
        !this.enableAssignVendorToClient && this.enableAssignVendorToTaskType
      )
    },
  },
})
</script>
