<template>
  <div>
    <!-- Check if assign and un assign actions are allowed -->
    <ResourceAllowedOperations
      v-if="clientActions === null"
      v-show="false"
      v-slot="{ allowsActions }"
      :resource-name="resourceName"
      @change:allowsActions="allowsClientActions = $event"
    >
      <EntityActions
        v-if="allowsActions"
        :entity-id="clientId"
        :resource-name="resourceName"
        @change="setClientActions($event)"
        @loading="onLoadingClientActions"
      />
    </ResourceAllowedOperations>
    <!-- / Check end -->
    <WidgetContainer :style="{ height: widgetContainerHeight }">
      <v-toolbar
        v-if="widget.title"
        ref="toolbar"
        class="toolbar3"
        dense
        flat
        :height="toolbarHeight"
        short
      >
        <v-toolbar-title
          :style="{ fontSize: '1.125rem' }"
          v-text="$t(widget.title)"
        />
      </v-toolbar>

      <WidgetFactory
        :key="JSON.stringify(widget)"
        :props-data="{
          container,
          style: { height: widgetHeight && `${widgetHeight}px` },
        }"
        :widget="widget"
        v-on="$listeners"
      />
    </WidgetContainer>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import { DataTableWidgetModel } from '@/tt-widget-components'
import { ColumnInputDefinition } from '@/tt-widget-components/widgets/DataTable/DataTableWidgetHook'
import {
  WidgetContainerInterface,
  WidgetContainerType,
} from '@/tt-widget-factory'
import { Resources } from '@/tt-entity-design/src/types'
import { WidgetName } from '@/tt-widget-components/lib/names'
import { Action } from '@/tt-widget-factory/services/resource-action/types'
import { EntityIntentTypes } from '@/tt-widget-entity-flow'
import { UnsubscribeFunction } from '@/tt-event-manager'
import { ClientActions } from '@/tt-entity-design/src/components/clients/types'

const TOOLBAR_HEIGHT = 60

export default Vue.extend({
  name: 'AssignVendorsWidget',
  props: {
    height: { type: Number, default: undefined },
    clientId: { type: Number, required: true },
  },
  data() {
    return {
      clientActions: null as Action[] | null,
      isLoadingActions: false,
      allowsClientActions: false,
      unsubscribe: null as UnsubscribeFunction | null,
    }
  },
  computed: {
    resourceName() {
      return Resources.CLIENTS
    },
    relationResourceName(): string | undefined {
      return Resources.VENDORS
    },
    relationAttributeName(): string {
      return 'vendors'
    },
    container(): WidgetContainerInterface {
      return {
        height: this.widgetHeight,
        hideTitle: true,
        type: WidgetContainerType.STANDALONE,
      }
    },
    allowUnassignVendors(): boolean {
      return this.clientActions?.some(
        ({ actionName }) => actionName === ClientActions.UnassignVendors,
      )
    },
    columns(): ColumnInputDefinition[] | undefined {
      const columns: ColumnInputDefinition[] = [
        {
          headerText: 'vendors.tabs.assign-vendors.table.headers.id',
          attributeName: 'id',
        },
        {
          headerText: 'vendors.tabs.assign-vendors.table.headers.name',
          attributeName: 'name',
        },
        {
          headerText: 'vendors.tabs.assign-vendors.table.headers.serviceTypes',
          attributeName: 'serviceTypes',
        },
        {
          headerText: 'vendors.tabs.assign-vendors.table.headers.fullName',
          attributeName: 'fullName',
        },
        {
          headerText: 'vendors.tabs.assign-vendors.table.headers.mainEmail',
          attributeName: 'mainEmail',
        },
        {
          headerText: 'vendors.tabs.assign-vendors.table.headers.primaryPhone',
          attributeName: 'primaryPhone',
        },
      ]
      const actionsColumn: ColumnInputDefinition = {
        headerText: 'vendors.tabs.assign-vendors.table.headers.actions',
        component: {
          is: 'AssignedSiteVendorsActionsColumn',
          props: {
            clientId: this.clientId,
          },
        },
        style: {
          width: 100,
        },
      }

      return this.allowUnassignVendors ? [...columns, actionsColumn] : columns
    },
    toolbarHeight(): number {
      return this.widget.title ? TOOLBAR_HEIGHT : 0
    },
    widget(): DataTableWidgetModel | null {
      return {
        title: '',
        is: WidgetName.DATA_TABLE_WIDGET,
        component: {
          columns: this.columns,
        },
        query: {
          resource: this.relationResourceName,
          relationListResource: {
            resource: this.resourceName,
            id: this.clientId,
            attribute: this.relationAttributeName,
          },
        },
        allowActions: true,
        toolbar: {
          filterOptions: {
            allowSearch: true,
            filters: [],
          },
          displayCounts: true,
        },
      }
    },
    widgetContainerHeight(): string | undefined {
      return this.height && `${this.height}px`
    },
    widgetHeight(): number {
      return this.height && this.height - this.toolbarHeight
    },
  },
  created() {
    this.unsubscribe = this.$eventManager.subscribeEvent(
      EntityIntentTypes.RESOURCE_UPDATED,
      ({ entityId, actionName, resource }) => {
        const isAssignAndUnassignAction = () =>
          resource === Resources.CLIENTS &&
          [ClientActions.AssignVendors, ClientActions.UnassignVendors].includes(
            actionName,
          )

        if (isAssignAndUnassignAction() && entityId === this.clientId) {
          this.$nextTick(() => {
            this.$eventManager.dispatchEvent(
              EntityIntentTypes.RESOURCE_UPDATED,
              {
                resource: this.relationResourceName,
              },
            )
          })
        }
      },
    )
  },
  beforeDestroy() {
    if (this.unsubscribe) this.unsubscribe()
  },
  methods: {
    setClientActions(actions: Action[]): void {
      this.clientActions = actions
    },
    onLoadingClientActions(loading: boolean): void {
      this.isLoadingActions = loading
    },
  },
})
</script>
